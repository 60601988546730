import { Card, PostsCardFeed } from "@/components/Card";
import { PostsCardFeedV2 } from "@/components/Card/PostsCardFeed/PostsCardFeedV2";
import { LoadingScreen } from "@/components/Loading";
import { LoginCTA } from "@/components/LoginCTA";
import { NotFound } from "@/components/Not-Found";
import { Box } from "@/components/styled";
import { MentionType, PostStatus } from "@/gql/graphql";
import { useFetchForYouFeed } from "@/hooks/useFetchForYouFeed";
import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";
import { useMySchoolFeed } from "@/hooks/useMySchoolFeed";
import { ApolloError } from "@apollo/client";
import { NoPostsYet } from "./NoPostsYet";

export const MySchoolFeed: React.FC = ({}) => {
  const { posts, error, loadMore, loading } = useMySchoolFeed();

  if (error) {
    <NotFound />;
  }

  return (
    <PostsCardFeedV2
      // @ts-ignore
      data={posts}
      loading={loading}
      loadMore={loadMore}
      notFoundPlaceHolder={<NoPostsYet />}
    />
  );
};
