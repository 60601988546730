import { graphql } from "@/gql";
import { useMutation } from "@apollo/client";

const ADD_REACTION_TO_DOCUMENT = graphql(`
  mutation AddReaction($input: AddStoryReactionInput!) {
    addStoryReaction(input: $input) {
      id
    }
  }
`);

export const useAddReactionToStory = () => {
  const [addStoryReaction, { data, loading }] = useMutation(
    ADD_REACTION_TO_DOCUMENT
  );
  return {
    addStoryReaction,
    data,
    loading,
  };
};
