import { PostsCardFeedV2 } from "@/components/Card/PostsCardFeed/PostsCardFeedV2";
import { NotFound } from "@/components/Not-Found";
import { MyStories } from "@/components/Stories/Components/MyStories";
import { Story } from "@/components/Stories/Components/Stories/Stories";
import { Box, Row } from "@/components/styled";
import { useFetchForYouFeed } from "@/hooks/useFetchForYouFeed";
import { useFetchForYouStories } from "@/hooks/useFetchForYourStories";
import { useFetchMyStories } from "@/hooks/useFetchMyStories";
import { useUserV2 } from "@/hooks/useUserV2";
import { BASE_COLORS } from "@/theme";
import { memo } from "react";

export const ForYou: React.FC = memo(() => {
  const { posts, error, loadMore, loading } = useFetchForYouFeed();
  const { user, error: userError, loading: userLoading } = useUserV2();
  const {
    data: myStories,
    loading: loadingMyStories,
    error: errorLoadingMyStories,
  } = useFetchMyStories();
  const { data: fetchedStories, loading: loadingStories } =
    useFetchForYouStories();

  if (error) {
    <NotFound />;
  }

  if (loadingMyStories && loadingStories) return null;

  return (
    <Box width={"100%"}>
      <Row
        width={"100%"}
        borderBottom={`1px solid ${BASE_COLORS.lightGreyTransparent}`}
      >
        <Box>
          <MyStories
            fetchedStories={myStories?.myStories}
            loadingStories={loadingMyStories}
            error={errorLoadingMyStories}
          />
        </Box>
        <Box>
          <Story
            fetchedStories={fetchedStories?.fetchForYouStories}
            loadingStories={loadingStories}
            error={error}
          />
        </Box>
      </Row>
      <Box>
        <PostsCardFeedV2
          // @ts-ignore
          data={posts}
          loading={loading}
          loadMore={loadMore}
          user={user}
          error={userError}
          userLoading={userLoading}
        />
      </Box>
    </Box>
  );
});
ForYou.displayName = "ForYou";
