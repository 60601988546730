"use client";
import Stories from "react-insta-stories";
import { Box, Row, Column, CenterBox, Text } from "@/components/styled";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { StoryModal } from "@/components/styled/StoryModal";
import { StoryComponent } from "../StoryComponent";
import { BASE_COLORS } from "@/theme";

type MyStoryProps = {
  fetchedStories: any[] | undefined;
  loadingStories: boolean;
  error: any;
};

export const MyStories: React.FC<MyStoryProps> = ({
  fetchedStories,
  loadingStories,
  error,
}) => {
  const [showStory, setShowStory] = useState(false);
  const [storyIndex, setStoryIndex] = useState(0);
  const [viewedStories, setViewedStories] = useState<string[]>([]);

  useEffect(() => {
    if (showStory) {
      localStorage.setItem("viewedStories", JSON.stringify(viewedStories));
    }
  }, [showStory, viewedStories]);

  const handleOpenStory = useCallback(() => {
    setShowStory(true);
  }, []);

  const handleCloseStory = useCallback(() => {
    setShowStory(false);
    setStoryIndex(0);
  }, []);

  const onAllStoriesEnd = useCallback(() => {
    handleCloseStory();
  }, []);

  const myStories = useMemo(() => {
    if (!fetchedStories) return [];
    return fetchedStories.map((story: any) => ({
      content: ({ action }: { action: (action: string) => void }) => (
        <StoryComponent content={story.story} action={action} />
      ),
      id: story.story.id,
      viewed: story.viewed,
    }));
  }, [fetchedStories]);

  const allMyStoriesViewed = () => {
    return myStories.every((story) => story.viewed);
  };

  const onStoryStart = () => {
    setStoryIndex(storyIndex + 1);
    const storyId = myStories[storyIndex]?.id;
    if (storyId && !viewedStories.includes(storyId)) {
      const updatedViewedStories = [...viewedStories, storyId];
      setViewedStories(updatedViewedStories);
    }
  };

  const onNext = useCallback(() => {
    setStoryIndex((prevIndex) => prevIndex + 1);
  }, []);

  const onPrevious = useCallback(() => {
    setStoryIndex((prevIndex) => Math.max(0, prevIndex - 1));
  }, []);

  const onStoryEnd = () => {
    if (storyIndex < myStories.length - 1) {
      setStoryIndex((prevIndex) => prevIndex + 1);
    }
  };

  const uniLogo = fetchedStories
    ? fetchedStories[0]?.story?.author?.school?.logo
    : "";

  if (fetchedStories && fetchedStories.length === 0) return null;

  return (
    <Box p={"md"}>
      <Box
        alignItems={"center"}
        style={{ cursor: "pointer" }}
        onClick={handleOpenStory}
      >
        <Box
          width={"64px"}
          height={"64px"}
          border={
            allMyStoriesViewed()
              ? `2px solid ${BASE_COLORS.grey}`
              : `2px solid ${BASE_COLORS.primary}`
          }
          p={"xxs"}
          borderRadius={"full"}
        >
          <Box
            width={"100%"}
            height={"100%"}
            borderRadius={"full"}
            backgroundImage={`url("${uniLogo}")`}
            backgroundPosition={"center"}
            backgroundSize={"contain"}
          />
        </Box>
        <Text fontSize={16}>My Stories</Text>
      </Box>
      {showStory ? (
        <StoryModal isOpen={showStory} onClose={handleCloseStory}>
          <Box
            width={"100%"}
            height={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{ zIndex: 1 }}
          >
            <Stories
              stories={myStories}
              defaultInterval={2000}
              width="100%"
              height="100%"
              storyStyles={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              loop={false}
              onStoryStart={onStoryStart}
              onStoryEnd={onStoryEnd}
              onAllStoriesEnd={onAllStoriesEnd}
              onNext={onNext}
              onPrevious={onPrevious}
              preloadCount={3}
            />
          </Box>
        </StoryModal>
      ) : null}
    </Box>
  );
};
