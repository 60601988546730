import { graphql } from "@/gql";
import { useQuery } from "@apollo/client";
import { useState } from "react";

export const MY_SCHOOL_FEED = graphql(`
  query FetchMySchoolFeed($input: Pagination!) {
    fetchMySchoolFeed(input: $input) {
      authorId
      title
      content
      school {
        id
        logo
        alias
        name
      }
      hashtags {
        id
        startIndex
        endIndex
      }
      mentions {
        startIndex
        endIndex
        id
        mentionedId
        mentionType
      }
      type
      nickname
      upvoteCount
      downvoteCount
      replyCount
      bookmarkCount
      viewCount
      id
      createdAt
    }
  }
`);

export const useMySchoolFeed = () => {
  const [endReached, setEndReached] = useState(false);
  const { data, loading, error, fetchMore } = useQuery(MY_SCHOOL_FEED, {
    variables: {
      input: {
        take: 10,
        skip: 0,
      },
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });

  const loadMore = () => {
    if (endReached === true) return;
    fetchMore({
      variables: {
        input: {
          take: 10,
          skip: data?.fetchMySchoolFeed.length || 0,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (fetchMoreResult.fetchMySchoolFeed.length === 0) {
          setEndReached(true);
          return {
            fetchMySchoolFeed: prev.fetchMySchoolFeed,
          };
        }
        if (!fetchMoreResult) return prev;
        return {
          fetchMySchoolFeed: [
            ...prev.fetchMySchoolFeed,
            ...fetchMoreResult.fetchMySchoolFeed,
          ],
        };
      },
    });
  };
  return {
    posts: data?.fetchMySchoolFeed || [],
    loading,
    error,
    loadMore,
  };
};
