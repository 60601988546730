"use client";
import Stories from "react-insta-stories";
import { useCallback, useState, useMemo, useEffect } from "react";
import { Box, Row, Text } from "@/components/styled";
import { StoryComponent } from "../StoryComponent/StoryComponent";
import { StoryModal } from "@/components/styled/StoryModal";
import { BASE_COLORS } from "@/theme";
import { Spinner } from "@/components/Spinner";

type StoryProps = {
  fetchedStories: any[] | undefined;
  loadingStories: boolean;
  error: any;
};

export const Story: React.FC<StoryProps> = ({
  fetchedStories,
  loadingStories,
  error,
}) => {
  const [activeStoryGroup, setActiveStoryGroup] = useState<number>(0);
  const [showStory, setShowStory] = useState(false);
  const [storyIndex, setStoryIndex] = useState(0);
  const [viewedStories, setViewedStories] = useState<string[]>([]);
  const [lastViewedStoryId, setLastViewedStoryId] = useState<string | null>(
    null
  );

  // Helper function to get unique viewed stories
  const getUniqueStoryIds = (): string[] => {
    return Array.from(new Set(viewedStories));
  };

  const users = useMemo(() => {
    if (!fetchedStories) return [];
    return fetchedStories.map((story) => ({
      schoolId: story.school.id,
      schoolLogo: story.school.logo,
      schoolName: story.school.name,
      schoolAlias: story.school.alias,
      schoolAlias2: story.school.secondaryAlias,
      stories: story.stories,
    }));
  }, [fetchedStories]);

  const currentUserStories = useMemo(() => {
    if (users.length === 0) return [];
    const activeUser = users[activeStoryGroup];
    if (!activeUser?.stories) return [];
    return activeUser.stories.map((story: any) => ({
      content: ({ action }: { action: (action: string) => void }) => (
        <StoryComponent content={story.story} action={action} />
      ),
      id: story.story.id,
      viewed: story.viewed,
    }));
  }, [users, activeStoryGroup]);

  // Save unique story IDs to localStorage, skipping viewed stories
  const saveViewedStories = useCallback(() => {
    if (!showStory) return;
    const uniqueStoryIds = getUniqueStoryIds();
    const unviewedStories = currentUserStories.filter(
      (story: { viewed: any; id: string }) =>
        !story.viewed && !uniqueStoryIds.includes(story.id) // Only cache unviewed stories
    );

    if (unviewedStories.length > 0) {
      localStorage.setItem(
        "viewedStories",
        JSON.stringify([
          ...uniqueStoryIds,
          ...unviewedStories.map((story: { id: any }) => story.id),
        ])
      );
    }
  }, [currentUserStories, viewedStories]);

  useEffect(() => {
    saveViewedStories(); // Call this when the viewedStories array updates
  }, [viewedStories, saveViewedStories]);

  const handleOpenStory = useCallback((index: number) => {
    setShowStory(true);
    setActiveStoryGroup(index);
    setStoryIndex(0);
  }, []);

  const handleCloseStory = useCallback(() => {
    setShowStory(false);
    setActiveStoryGroup(0);
    setStoryIndex(0);
  }, []);

  const onStoryStart = () => {
    const currentStoryId = currentUserStories[storyIndex].id;
    if (currentStoryId && currentStoryId !== lastViewedStoryId) {
      setViewedStories((prev) => [...prev, currentStoryId]);
      setLastViewedStoryId(currentStoryId);
    }
  };

  const onStoryEnd = () => {
    if (storyIndex < currentUserStories.length - 1) {
      setStoryIndex((prevIndex) => prevIndex + 1);
    }
  };

  const onAllStoriesEnd = useCallback(() => {
    if (activeStoryGroup !== null) {
      const nextStoryIndex = currentUserStories.findIndex(
        (story: { viewed: any }, index: number) =>
          index > activeStoryGroup && !story.viewed
      );
      if (nextStoryIndex !== -1) {
        setActiveStoryGroup(nextStoryIndex);
        setStoryIndex(0);
      } else {
        handleCloseStory();
      }
    }
  }, [activeStoryGroup, currentUserStories, handleCloseStory]);

  const onNext = useCallback(() => {
    setStoryIndex((prevIndex) => prevIndex + 1);
  }, []);

  const onPrevious = useCallback(() => {
    setStoryIndex((prevIndex) => Math.max(0, prevIndex - 1));
  }, []);

  const handleToggleStory = useCallback(() => {
    setShowStory(false);
  }, []);

  const areAllStoriesViewed = (user: { stories: { viewed: boolean }[] }) => {
    return user.stories.every((story) => story.viewed);
  };

  if (loadingStories) return <Spinner />;
  if (error) return null;
  if (users.length === 0) return null;

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Row
        width={"100%"}
        py={"md"}
        px={"md"}
        flexDirection={"row"}
        justifyContent={"start"}
        alignItems={"center"}
        gap={"20px"}
      >
        {users.map((user, _index) => (
          <Box
            key={_index}
            alignItems={"center"}
            style={{ cursor: "pointer" }}
            onClick={() => handleOpenStory(_index)}
          >
            <Box
              width={"64px"}
              height={"64px"}
              border={
                areAllStoriesViewed(user)
                  ? `2px solid ${BASE_COLORS.grey}`
                  : `2px solid ${BASE_COLORS.primary}`
              }
              p={"xxs"}
              borderRadius={"full"}
            >
              <Box
                width={"100%"}
                height={"100%"}
                borderRadius={"full"}
                backgroundImage={`url("${user.schoolLogo}")`}
                backgroundPosition={"center"}
                backgroundSize={"contain"}
              />
            </Box>
            <Text fontSize={16}>{user.schoolAlias.slice(0, 10) + "..."}</Text>
          </Box>
        ))}
      </Row>
      {showStory && currentUserStories.length > 0 && (
        <StoryModal isOpen={showStory} onClose={handleToggleStory}>
          <Box
            width={"100%"}
            height={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{ zIndex: 1 }}
          >
            <Stories
              stories={currentUserStories}
              defaultInterval={2000}
              width="100%"
              height="100%"
              storyStyles={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              loop={false}
              onStoryStart={onStoryStart}
              onStoryEnd={onStoryEnd}
              onAllStoriesEnd={onAllStoriesEnd}
              onNext={onNext}
              onPrevious={onPrevious}
              preloadCount={3}
            />
          </Box>
        </StoryModal>
      )}
    </Box>
  );
};
