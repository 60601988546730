import { graphql } from "@/gql";
import { useQuery } from "@apollo/client";

const FETCH_MY_STORIES_DOCUMENT = graphql(`
  query MyStories {
    myStories {
      viewed
      story {
        id
        content
        postType
        type
        viewCount
        createdAt
        deletedAt
        expiresAt
        author {
          school {
            logo
            id
            name
            alias
            secondaryAlias
          }
        }
        reactions {
          id
          type
        }
        post {
          id
          type
          authorId
          nickname
          title
          parentId
          content
          status
          greekRankId
          replyCount
          upvoteCount
          downvoteCount
          bookmarkCount
          viewCount
          createdAt
          updatedAt
          deletedAt
          flagCount
          storyShareCount
          mentions {
            id
            startIndex
            endIndex
            mentionType
            mentionedId
          }
          hashtags {
            id
            startIndex
            endIndex
            tag
          }
        }
      }
    }
  }
`);

export const useFetchMyStories = () => {
  const response = useQuery(FETCH_MY_STORIES_DOCUMENT);
  return response;
};
