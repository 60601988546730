import { graphql } from "@/gql";
import { gql, useQuery } from "@apollo/client";
import { useState } from "react";

export const FETCH_ALL_POSTS = graphql(`
  query FetchAllPostsFeed($input: Pagination!) {
    fetchAllPostsFeed(input: $input) {
      authorId
      title
      content
      school {
        id
        logo
        alias
        name
      }
      hashtags {
        id
        startIndex
        endIndex
      }
      mentions {
        startIndex
        endIndex
        id
        mentionedId
        mentionType
      }
      type
      nickname
      upvoteCount
      downvoteCount
      viewCount
      replyCount
      bookmarkCount
      id
      createdAt
      status
    }
  }
`);

export const useFetchAllPosts = () => {
  const [endReached, setEndReached] = useState(false);

  const { data, loading, error, fetchMore } = useQuery(FETCH_ALL_POSTS, {
    variables: {
      input: {
        take: 10,
        skip: 0,
      },
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });

  const loadMore = () => {
    if (endReached === true) return;
    fetchMore({
      variables: {
        input: {
          take: 10,
          skip: data?.fetchAllPostsFeed?.length || 0,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (fetchMoreResult?.fetchAllPostsFeed?.length === 0) {
          setEndReached(true);
          return {
            fetchAllPostsFeed: prev.fetchAllPostsFeed,
          };
        }
        if (!fetchMoreResult) return prev;
        return {
          fetchAllPostsFeed: [
            ...prev.fetchAllPostsFeed,
            ...fetchMoreResult.fetchAllPostsFeed,
          ],
        };
      },
    });
  };
  return {
    posts: data?.fetchAllPostsFeed || [],
    loading,
    error,
    loadMore,
  };
};
