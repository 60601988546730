import { Text, TextProps } from "../styled";

export type IconNames = keyof typeof iconMap;
type IconsProps = {
  name: IconNames;
  onClick?: () => void;
} & TextProps;
const iconMap = {
  upChev: "icon-up-chev",
  downChev: "icon-down-chev",
  rightChev: "icon-right-chev",
  leftChev: "icon-left-chev",
  search: "icon-search",
  plus: "icon-plus",
  close: "icon-cross",
  leftArrow: "icon-left-arrow",
  rightArrow: "icon-right-arrow",
  backIcon: "icon-backIcon",
  hamburgerMenu: "icon-hamburger",
};

export const Icon = ({ name, onClick, ...props }: IconsProps) => {
  const className = iconMap?.[name];
  return <Text onClick={onClick} className={className} {...props}></Text>;
};
