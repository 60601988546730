import { useFetchAllPosts } from "@/app/auth/hooks/useFetchAllPosts";
import { PostsCardFeedV2 } from "@/components/Card/PostsCardFeed/PostsCardFeedV2";
import { NotFound } from "@/components/Not-Found";

export const GuestForYou: React.FC = ({}) => {
  const { posts, error, loadMore, loading } = useFetchAllPosts();

  if (error) {
    <NotFound />;
  }

  return (
    <PostsCardFeedV2
      // @ts-ignore
      data={posts}
      loading={loading}
      loadMore={loadMore}
    />
  );
};
