import { CenterBox, Text, Button } from "@/components/styled";
import { useRouter } from "next/navigation";

export const NoPostsYet: React.FC = () => {
  const router = useRouter();
  return (
    <CenterBox gap="2xl">
      <Text color="primary" size={20} textAlign="center" px={"lg"}>
        Your Selected University doent have any posts posted yet.
      </Text>
      <Text color="primary" size={20} textAlign="center">
        You Can check other posts
      </Text>
      <Button
        width="200px"
        variant="primary"
        onClick={() => router.push("/home/for-you")}
      >
        EXPLORE POSTS
      </Button>
    </CenterBox>
  );
};
