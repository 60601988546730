import { Box, Row, Column, CenterBox, Text, Button } from "@/components/styled";
import { useLottiePlayer } from "@/hooks/useLottiePlayer";
import { BASE_COLORS } from "@/theme";
import Image from "next/image";
import { useRouter } from "next/navigation";
// import LoginCTAImage from "@/public/assets/images/svgimages/login-cta.png";
import onboardingImage from "@/public/assets/images/pngimages/onboarding.png"

export const LoginCTA: React.FC = () => {
  const router = useRouter();
  const DynamicLottiePlayer = useLottiePlayer();

  const handleSignin = () => {
    router.push("/auth/signin");
  };
  return (
    <CenterBox gap={"2xl"} px={"sm"}>
      {/* <Text textAlign={"center"} fontSize={[20, 27]}>
        <Text
          onClick={handleSignin}
          style={{ color: BASE_COLORS.primary, cursor: "pointer" }}
        >
          Log in
        </Text>{" "}
        to unlock full RushLink experience
      </Text> */}
      <Text fontSize={[20, 27]} textAlign={['center', 'start']}>👋 Hey there!</Text>
      <Text fontSize={[20, 27]} textAlign={["center"]}>Sign in to unlock complete RushLink. experience</Text>
      <Box
          backgroundImage={`url(${onboardingImage.src})`} 
          backgroundPosition="center"
          backgroundSize="contain"
          height={300}
          width={[330, 450]}
          backgroundRepeat="no-repeat"
>

     
      </Box>
      <Button
        variant="primary"
        // width={"220px"}
        // height={"40px"}
        px={"xl"}
        py={"md"}
        fontSize={20}
        onClick={handleSignin}
      >
        Access RushLink Account
      </Button>
    </CenterBox>
  );
};
