import { graphql } from "@/gql";
import { useQuery } from "@apollo/client";
import { useState } from "react";

export const FETCH_FOLLOWING_FEED = graphql(`
  query FetchFollowingFeed($input: Pagination!) {
    fetchFollowingFeed(input: $input) {
      authorId
      title
      content
      school {
        id
        logo
        alias
        name
      }
      hashtags {
        id
        startIndex
        endIndex
      }
      mentions {
        startIndex
        endIndex
        id
      }
      type
      nickname
      viewCount
      upvoteCount
      downvoteCount
      replyCount
      bookmarkCount
      id
      createdAt
    }
  }
`);

export const useFetchFollowingFeed = () => {
  const [endReached, setEndReached] = useState(false);
  const { data, loading, error, fetchMore } = useQuery(FETCH_FOLLOWING_FEED, {
    variables: {
      input: {
        take: 10,
        skip: 0,
      },
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });

  const loadMore = () => {
    if (endReached === true) return;
    fetchMore({
      variables: {
        input: {
          take: 10,
          skip: data?.fetchFollowingFeed.length || 0,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (fetchMoreResult?.fetchFollowingFeed?.length === 0) {
          setEndReached(true);
          return {
            fetchFollowingFeed: prev.fetchFollowingFeed,
          };
        }
        if (!fetchMoreResult) return prev;
        return {
          fetchFollowingFeed: [
            ...prev.fetchFollowingFeed,
            ...fetchMoreResult.fetchFollowingFeed,
          ],
        };
      },
    });
  };

  return {
    posts: data?.fetchFollowingFeed || [],
    loading,
    error,
    loadMore,
  };
};
