"use client";
import { usePathname } from "next/navigation";
import { ReactNode } from "react";

import { Box } from "@/components/styled";
import { ForYou } from "../Components/For-You/For-You";
import { Following } from "../Components/Following";
import { MySchoolFeed } from "../Components/MySchool/MySchoolFeed";
import { GuestForYou } from "../Components/GuestForYou/GuestForYou";
import { useUserV2 } from "@/hooks/useUserV2";
import { MySchool } from "../Components/MySchool/MySchool";
import { LoadingScreen } from "@/components/Loading";
import { Text } from "@/components/styled";

export const Section = () => {
  const pathname = usePathname();
  const urlSections = pathname.split("/");
  const { isLoggedIn, loading } = useUserV2();
  const extractedlink = urlSections[urlSections.length - 1];

  if (loading) return <LoadingScreen />;

  const SchoolComponents: Record<string, ReactNode> = {
    myschool: <MySchool />,
    "for-you": isLoggedIn ? <ForYou /> : <GuestForYou />,
    following: <Following />,
  };
  return (
    <Box mt="2xl">{SchoolComponents[extractedlink] || <>Page not found</>}</Box>
  );
};
