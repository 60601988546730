import { LoginCTA } from "@/components/LoginCTA";
import { MySchoolFeed } from "./MySchoolFeed";
import { useUserV2 } from "@/hooks/useUserV2";

export const MySchool = () => {
  const { isLoggedIn, loading } = useUserV2();

  if (!loading && !isLoggedIn) {
    return <LoginCTA />;
  }

  return <MySchoolFeed />;
};
