import { graphql } from "@/gql";
import { useQuery } from "@apollo/client";
import { useState } from "react";

export const FETCH_FOR_YOU_FEED = graphql(`
  query FetchForYouFeed($input: Pagination!) {
    fetchForYouFeed(input: $input) {
      authorId
      title
      content
      school {
        id
        logo
        alias
        name
      }
      hashtags {
        id
        startIndex
        endIndex
      }
      mentions {
        startIndex
        endIndex
        id
        mentionedId
        mentionType
      }
      type
      nickname
      upvoteCount
      viewCount
      downvoteCount
      replyCount
      bookmarkCount
      id
      createdAt
      status
    }
  }
`);

// export const useFetchForYouFeed = (skip?: number) => {
//   const response = useQuery(FETCH_FOR_YOU_FEED, {
//     variables: {
//       input: {
//         take: 10,
//         skip: skip || 0,
//       },
//     },
//     nextFetchPolicy: "network-only",
//     fetchPolicy: "network-only",
//   });
//   return response;
// };

export const useFetchForYouFeed = (initialSkip = 0) => {
  const [endReached, setEndReached] = useState(false);
  const { data, loading, error, fetchMore } = useQuery(FETCH_FOR_YOU_FEED, {
    variables: {
      input: {
        take: 10,
        skip: initialSkip,
      },
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });

  const loadMore = () => {
    if (endReached === true) return;
    fetchMore({
      variables: {
        input: {
          take: 10,
          skip: data?.fetchForYouFeed.length || 0,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (fetchMoreResult?.fetchForYouFeed?.length === 0) {
          setEndReached(true);
          return {
            fetchForYouFeed: prev.fetchForYouFeed,
          };
        }
        if (!fetchMoreResult) return prev;
        return {
          fetchForYouFeed: [
            ...prev.fetchForYouFeed,
            ...fetchMoreResult.fetchForYouFeed,
          ],
        };
      },
    });
  };

  return {
    posts: data?.fetchForYouFeed || [],
    loading,
    error,
    loadMore,
  };
};
