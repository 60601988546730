import { Box, Row, Column, CenterBox, Text } from "@/components/styled";
import Image from "next/image";
import styled from "styled-components";

const PhotoStoryContainer = styled(Box)`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;

const PhotoStoryText = styled(Text)`
  position: absolute;
  font-size: 24;
`;

type PhotoStoryProps = {
  photoStoryData: {
    photoUrl: string;
    caption: string;
    textPosX: string;
    textPosY: string;
    textHexCode: string;
  };
};

export const PhotoStory: React.FC<PhotoStoryProps> = ({ photoStoryData }) => {
  const { photoUrl, textHexCode, textPosX, textPosY, caption } = photoStoryData;
  const captionX = Number(textPosX);
  const captionY = Number(textPosY);

  return (
    <PhotoStoryContainer backgroundImage={`url("${photoUrl}")`}>
      <PhotoStoryText
        style={{
          left: captionX,
          top: captionY,
          color: textHexCode,
        }}
      >
        {caption}
      </PhotoStoryText>
    </PhotoStoryContainer>
  );
};
