import { LoginCTA } from "@/components/LoginCTA";
import { useUserV2 } from "@/hooks/useUserV2";
import { FollowingFeed } from "./FollowingFeed";

export const Following = () => {
  const { isLoggedIn, loading } = useUserV2();

  if (!loading && !isLoggedIn) {
    return <LoginCTA />;
  }
  return <FollowingFeed />;
};
