import { Box, Row, Column, CenterBox, Text, Button } from "@/components/styled";
import Image from "next/image";
import { useRouter } from "next/navigation";

export const NotYetFollowingPlacehold: React.FC = () => {
  const router = useRouter();
  return (
    <CenterBox pt={"xl"} gap="2xl">
      <Text textAlign={"center"} color="primary" size={20}>
        {`No one here yet! Start following to fill this space with awesome content.`}
      </Text>
      <Button
        variant="primary"
        width="200px"
        onClick={() => router.push("/home/for-you")}
      >
        Explore
      </Button>
    </CenterBox>
  );
};
