import { Box, Row, Column, CenterBox, Text } from "@/components/styled";
import { BASE_COLORS } from "@/theme";
import { useEffect, useState } from "react";
import styled from "styled-components";

const FullScreenStoryContainer = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  transition: all 0.3s ease-in-out;
`;

const StoryContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
`;

type StoryModal = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

export const StoryModal: React.FC<StoryModal> = ({
  isOpen,
  onClose,
  children,
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  if (!mounted) return null;
  return (
    <FullScreenStoryContainer onClick={onClose}>
      <StoryContent
        width={["100%", "393px"]}
        height={["100%", "95vh"]}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </StoryContent>
    </FullScreenStoryContainer>
  );
};
