import React, { memo, useCallback, useEffect, useState } from "react";
import { Box, Button, Row, Text } from "@/components/styled";
import { Story, StoryReactionType, StoryType } from "@/gql/graphql";
import { BASE_COLORS, BASE_SPACING } from "@/theme";
import { getRelativePostTime } from "@/utils/getRelativePostTime";
import styled from "styled-components";
import { ShareStoryTextPost } from "../../ShareStoryTextPost";
import { ShareStoryPoll } from "../../ShareStoryPoll";
import { ShareStoryRankingV2 } from "../../ShareStoryRankingV2";
import { ShareStoryRanking } from "../../ShareStoryRanking";
import { ShareStoryOrganizationReview } from "../../ShareStoryOrganizationReview";
import { ShareStorySchoolReview } from "../../ShareStorySchoolReview";
import { Icon } from "@/components/Icon";
import { useAddReactionToStory } from "@/hooks/useAddReactionToStory";
import toast from "react-hot-toast";
import Image from "next/image";
import { PhotoStory } from "./Components/PhotoStory";
import { useRouter } from "next/navigation";

const StoryComponentContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
  background: linear-gradient(
    to bottom right,
    ${BASE_COLORS.primary},
    #993434,
    ${BASE_COLORS.black}
  );
`;

const StoryHeader = styled(Row)`
  width: 100%;
  padding-top: ${BASE_SPACING.xs}px;
  padding-bottom: ${BASE_SPACING.xs}px;
  position: absolute;
  top: 25px;
  left: 0;
  padding-left: ${BASE_SPACING.sm}px;
  padding-right: ${BASE_SPACING.sm}px;
  justify-content: space-between;
  z-index: 1000;
`;

const StoryFooter = styled(Box)<{ footerOpen: boolean }>`
  clip-path: polygon(50% 0%, 100% 20%, 100% 100%, 0 100%, 0 20%);
  background-color: rgba(0, 0, 0, 0.2);
  align-items: center;
  width: 100%;
  height: ${({ footerOpen }) => (footerOpen ? "30%" : "5%")};
  padding-top: ${BASE_SPACING.md}px;
  padding-bottom: ${BASE_SPACING.md}px;
  position: absolute;
  bottom: 0;
  left: 0;
  gap: ${BASE_SPACING.md}px;
  padding-left: ${BASE_SPACING.sm}px;
  padding-right: ${BASE_SPACING.sm}px;
  transition: height 0.3s ease-in-out;
  z-index: 1000;
`;

const ReactionsContainer = styled(Row)<{ footerOpen: boolean }>`
  width: 50%;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${BASE_SPACING.xl}px;
  transform: translateY(${({ footerOpen }) => (footerOpen ? "0" : "30%")});
  opacity: ${({ footerOpen }) => (footerOpen ? 1 : 0)};
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  z-index: 1000;
`;

const StoryContentContainer = styled(Box)`
  justify-content: center;
  align-items: center;
  z-index: 500;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StoryComponents = {
  TEXT: ShareStoryTextPost,
  POLL: ShareStoryPoll,
  RANKING_V2: ShareStoryRankingV2,
  RANKING: ShareStoryRanking,
  ORGANIZATION_REVIEW: ShareStoryOrganizationReview,
  SCHOOL_REVIEW: ShareStorySchoolReview,
};

type StoryComponentProps = {
  content: Story;
  onClose?: () => void;
  action: (action: string) => void;
};

const ReactionList = [
  { name: "Like", type: StoryReactionType.Like, emoji: "👍" },
  { name: "Love", type: StoryReactionType.Love, emoji: "❤️" },
  { name: "Haha", type: StoryReactionType.Haha, emoji: "😂" },
  { name: "Wow", type: StoryReactionType.Wow, emoji: "😱" },
  { name: "Sad", type: StoryReactionType.Sad, emoji: "😔" },
  { name: "Angry", type: StoryReactionType.Angry, emoji: "😡" },
];

type ReactionProps = {
  name: string;
  type: StoryReactionType;
  emoji: string;
};

const ReactionBase = styled(Box)`
  transform: scale(1);
  transition: transform 0.1s ease-in-out;
  z-index: 1000;
  &:hover {
    transform: scale(1.5);
  }
`;

const Reaction: React.FC<ReactionProps> = ({ name, type, emoji }) => {
  return (
    <ReactionBase style={{ cursor: "pointer", zIndex: 1000 }}>
      <Text fontSize={38}>{emoji}</Text>
    </ReactionBase>
  );
};

const StoryComponentBase: React.FC<StoryComponentProps> = ({
  content,
  onClose,
  action,
}) => {
  const [footerOpen, setFooterOpen] = useState<boolean>(false);
  const router = useRouter();
  //@ts-ignore
  const StoryPost = StoryComponents[content?.postType];

  const storyProps = {
    title: content.post?.title,
    hashtags: content.post?.hashtags,
    username: content.post?.nickname,
    id: content.post?.id,
    schoolLogo: content?.author.school?.logo,
    universityName: content?.author.school?.name,
    tags: content.post?.mentions,
    postBy: content.post?.nickname,
    views: content.viewCount,
    viewCount: content.viewCount,
    upVotes: content.post?.upvoteCount,
    downVotes: content.post?.downvoteCount,
    comments: content.post?.replyCount,
    sharedPosts: 0,
    savedPosts: content.post?.bookmarkCount,
    type: content.post?.type,
    createdAt: content.post?.createdAt,
    authorId: content.post?.authorId,
    cardComments: content.content,
    isSharedAsStory: true,
  };

  const { addStoryReaction } = useAddReactionToStory();

  const handleAddReactionToStory = useCallback(
    async (reactionType: StoryReactionType) => {
      try {
        const response = await toast.promise(
          addStoryReaction({
            variables: {
              input: {
                storyId: content.id,
                reactionType,
              },
            },
          }),
          {
            loading: "Adding Reaction",
            success: "Added Reaction",
            error: "Something Went Wrong",
          }
        );
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const openReactionDrawer = () => {
    setFooterOpen((prev) => {
      const newState = !prev;
      if (newState) {
        action("pause");
      } else {
        action("play");
      }
      return newState;
    });
  };

  const handleAddReaction = (reactionType: StoryReactionType) => {
    handleAddReactionToStory(reactionType);
    setFooterOpen((prev) => {
      const newState = !prev;
      if (newState) {
        action("pause");
      } else {
        action("play");
      }
      return newState;
    });
  };

  const handleViewPost = useCallback(() => {
    if (!content?.post?.id) return;
    router.push(`/post/${content?.post?.id}`);
  }, [content?.post?.id, router]);

  const photoStoryData = (): {
    photoUrl: string;
    caption: string;
    textPosX: string;
    textPosY: string;
    textHexCode: string;
  } => {
    if (content && content.content) return JSON.parse(content.content);
    else
      return {
        photoUrl: "",
        caption: "",
        textPosX: "",
        textPosY: "",
        textHexCode: "",
      };
  };

  const renderContent = () => {
    if (content.type === StoryType.Post) {
      return <StoryPost {...storyProps} />;
    } else if (content.type === StoryType.Photo) {
      return <PhotoStory photoStoryData={photoStoryData()} />;
    } else if (content.type === StoryType.Video) {
      return <Box>Video</Box>;
    }
  };

  return (
    <StoryComponentContainer>
      <StoryHeader>
        {content.author ? (
          <Row gap={"sm"} alignItems={"center"}>
            <Box
              width={"50px"}
              height={"50px"}
              borderRadius={"full"}
              backgroundImage={`url("${content.author.school?.logo}")`}
              backgroundPosition={"center"}
              backgroundSize={"cover"}
              zIndex={1000}
            ></Box>
            <Box gap={"sm"}>
              <Row gap={"md"} alignItems={"center"}>
                <Text color="white">
                  {content.author.school?.alias.slice(0, 20) + "..."}
                </Text>
                <Box
                  width={3}
                  height={3}
                  backgroundColor={"white"}
                  borderRadius={"full"}
                />
                <Text color="grey2" fontSize={14}>
                  {getRelativePostTime(content.createdAt)}
                </Text>
              </Row>
              {content.type === StoryType.Post && content?.post?.id ? (
                <Box>
                  <Button
                    variant="altPrimary"
                    width={"fit-content"}
                    py={"xxs"}
                    borderRadius={"md"}
                    onClick={handleViewPost}
                  >
                    View Post
                  </Button>
                </Box>
              ) : null}
            </Box>
          </Row>
        ) : null}
        {/* {content.viewCount ? (
          <Row gap={"sm"} alignItems={"center"}>
            <Image
              src={"/assets/icons/views.svg"}
              alt={"Views"}
              width={20}
              height={20}
            />
            <Text>{content.viewCount}</Text>
          </Row>
        ) : null} */}
      </StoryHeader>
      <StoryContentContainer>{renderContent()}</StoryContentContainer>
      <StoryFooter footerOpen={footerOpen} justifyContent={"center"}>
        <Icon
          name={footerOpen ? "downChev" : "upChev"}
          size={26}
          //@ts-ignore
          style={{
            cursor: "pointer",
            zIndex: 1000,
            position: "absolute",
            top: "8px",
          }}
          onClick={openReactionDrawer}
        />
        <ReactionsContainer footerOpen={footerOpen}>
          {footerOpen &&
            ReactionList.map((reaction, index) => (
              <Box key={index} onClick={() => handleAddReaction(reaction.type)}>
                <Reaction
                  key={index}
                  name={reaction.name}
                  type={reaction.type}
                  emoji={reaction.emoji}
                />
              </Box>
            ))}
        </ReactionsContainer>
      </StoryFooter>
    </StoryComponentContainer>
  );
};

StoryComponentBase.displayName = "StoryComponent";

export const StoryComponent = memo(StoryComponentBase);
