import { graphql } from "@/gql";
import { gql, useQuery } from "@apollo/client";
import { skip } from "node:test";

export const FETCH_FOR_YOU_STORIES_DOCUMENT = graphql(`
  query ForYouStories($input: Pagination!) {
    fetchForYouStories(input: $input) {
      school {
        id
        logo
        name
        location
        secondaryAlias
        alias
      }
      stories {
        viewed
        story {
          author {
            school {
              id
              logo
              name
              alias
            }
          }
          authorId
          id
          type
          viewCount
          reactions {
            type
            id
          }
          content
          expiresAt
          createdAt
          postType
          post {
            id
            type
            authorId
            nickname
            title
            parentId
            content
            status
            greekRankId
            replyCount
            upvoteCount
            downvoteCount
            bookmarkCount
            viewCount
            createdAt
            updatedAt
            deletedAt
            flagCount
            storyShareCount
            mentions {
              id
              startIndex
              endIndex
              mentionType
              mentionedId
            }
            hashtags {
              id
              startIndex
              endIndex
              tag
            }
          }
        }
      }
    }
  }
`);

export const useFetchForYouStories = () => {
  const response = useQuery(FETCH_FOR_YOU_STORIES_DOCUMENT, {
    variables: { input: { skip: 0, take: 1000 } },
  });
  return response;
};
